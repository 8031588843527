import React, { useState, useEffect } from "react";
import IndividualPlayhouse from "./IndividualPlayhouse";
import { initializeApp } from "firebase/app";
import { getFirestore, doc, getDoc } from "firebase/firestore";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIRESTORE_API_KEY,
    authDomain: "wishuponaplayhouse.firebaseapp.com",
    projectId: "wishuponaplayhouse",
    storageBucket: "wishuponaplayhouse.appspot.com",
    messagingSenderId: "513377368862",
    appId: "1:513377368862:web:eadda2a0b7bb141ffa670f",
    measurementId: "G-NZ116LS0Q1",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

function SweetShoppe({ ...props }) {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [price, setPrice] = useState("");
    const [features, setFeatures] = useState([]);
    const [interiorOptions, setInteriorOptions] = useState([]);
    const [exteriorOptions, setExteriorOptions] = useState([]);
    const [playhouseMeasurements, setPlayhouseMeasurements] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const docRef = doc(firestore, "Pages", "sweetshopPage");
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setTitle(data.title);
                    setDescription(data.description);
                    setPrice(data.price);
                    setFeatures(data.features || []);
                    setInteriorOptions(data.interiorOptions || []);
                    setExteriorOptions(data.exteriorOptions || []);
                    setPlayhouseMeasurements(data.playhouseMeasurements || []);
                }
            } catch (error) {
                console.error("Error fetching homepage content: ", error);
            }
        };

        fetchData();
    }, []);
    //     const name = "The Sweet Shop";
    //     const price = "$5400.00";
    //     const description =
    //         "Our adorable Sweet Shop is just as sweet as can be.  Gingerbread trim, striped wooden awnings, and flower boxes add to its charm.  A child-sized Dutch door, candy striped knobs, and Plexiglas window make it easy for little ones to peek out and let the sunshine in.  This spacious interior with a ceiling height of 81“ includes 4 working windows with safety glass, grids and screens.  Painted pretty in pink, or your choice of color, and a personalized door plaque make the Sweet Shop your child’s very own. ";
    //     const features = [
    //         [
    //             "Custom Dutch Door with Plexiglass Window and Hardware",
    //             '23"w x 53"h',
    //         ],
    //         [
    //             "Four Aluminum Single Hung Working Windows With Grids, Screens & Safety Glass",
    //             'Front - 20"w x 30"h (2) Sides - 36"w x 30"h (2)',
    //         ],
    //         ["Two Sets Of Shutters", '9"w x 30"h (Each)'],
    //         [" Cottage Lap Siding"],
    //         [" Gingerbread Fascia"],
    //         [" Asphalt Roof Shingles"],
    //         ["Unfinished Interior"],
    //         ["Plywood Floor"],
    //         ["Treated Floor Joists"],
    //     ];
    //     const interiorOptions = [
    //         ["Simulated Hardwood Floor", "$220.00"],
    //         [
    //             "Primed White Beadboard Walls, Baseboards, Window & Door Casing",
    //             "$400.00",
    //         ],
    //         ["Interior Paint", "$300.00"],
    //     ];
    //     const exteriorOptions = [
    //         ["Awnings on 2 Front Windows", "$60.00"],
    //         ["Window Boxes", "$60.00"],
    //         ["Chimney", "$60.00"],
    //         ["Personalized Plaque Over Door", "$125.00"],
    //         ["Deck - 3' x 10'", "$250.00"],
    //         ["Railing - White PVC Severe Weather", "$370.00"],
    //         ["Exterior Paint", "$400.00"],
    //     ];
    //     const playhouseMeasurements = `Exterior - 10'w x 8'd
    // Interior - 7'4"w x 9'4"d
    // Exterior Height - 9' h
    // Interior Ceiling Height - 6'9"h`;
    return (
        <div className="aboutContainer">
            <IndividualPlayhouse
                name={title}
                price={price}
                description={description}
                features={features}
                interiorOptions={interiorOptions}
                exteriorOptions={exteriorOptions}
                playhouseMeasurements={playhouseMeasurements}
                pageName={"sweetshopPage"}
                isAdmin={props.isAdmin}
                setShowEdits={props.setShowEdits}
                showEdits={props.showEdits}
            />
        </div>
    );
}

export default SweetShoppe;

import React, { useState } from "react";
import "../styles/Contact.css";
import Navbar from "./NavBar";
import Footer from "./Footer";

const Contact = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Here you can handle form submission, e.g., send data to server or display in console
        console.log(formData);
        // Optionally, you can reset the form after submission
        setFormData({
            name: "",
            email: "",
            message: "",
        });
    };

    return (
        <div className="ContactContainer">
            <Navbar />
            <div className="topBuffer"></div>
            <form className="contact-form" onSubmit={handleSubmit}>
                <h2>Contact Us!</h2>
                <p>
                    You may contact us by filling in this form any time you need
                    professional support or have any questions. You can also
                    fill in the form to leave your comments or feedback.
                </p>
                <label htmlFor="name">Name:</label>
                <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                />

                <label htmlFor="email">Email:</label>
                <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                />

                <label htmlFor="message">Message:</label>
                <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                />

                <button type="submit">Submit</button>
            </form>
            <Footer newClassName={""} extension={"./"} />
        </div>
    );
};

export default Contact;

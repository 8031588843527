import React from "react";
import { Link } from "react-router-dom";

function Footer({ newClassName, extension }) {
    // console.log(newClassName);
    return (
        <div className={`footer ${newClassName}`}>
            <img src={`${extension}images/dandillion-l.jpeg`} alt="left" />
            <p>
                Thank you for visiting our website.
                <br />
                <br />
                Feel free to browse and learn more about our playhouses, and
                contact us for more information on how to initiate your
                playhouse order today!
                <br />
                <br />
                <a href="mailto:info@wishuponaplayhouse.com">
                    info@wishuponaplayhouse.com
                </a>
                <br />
                <br />
                (303) 284-0016
                <br />
                <br />
                Follow us on{" "}
                <a
                    href="https://www.facebook.com/profile.php?id=100048386175392"
                    target="_blank"
                    rel="noreferrer"
                >
                    Facebook
                </a>
                <br />
                <br />
                <Link to="/login">Login</Link>
            </p>
            <img src={`${extension}images/dandillion-r.jpeg`} alt="right" />
        </div>
    );
}

export default Footer;

import React, { useState, useEffect } from "react";
import Navbar from "../NavBar";
import Footer from "../Footer";
import "../../styles/Reviews.css";
import { initializeApp } from "firebase/app";
import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIRESTORE_API_KEY,
    authDomain: "wishuponaplayhouse.firebaseapp.com",
    projectId: "wishuponaplayhouse",
    storageBucket: "wishuponaplayhouse.appspot.com",
    messagingSenderId: "513377368862",
    appId: "1:513377368862:web:eadda2a0b7bb141ffa670f",
    measurementId: "G-NZ116LS0Q1",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

const Reviews = () => {
    const [reviewsData, setReviewsData] = useState([]);
    const [addTestimonial, setAddTestimonial] = useState(false);
    const [newReview, setNewReview] = useState({
        name: "",
        age: "",
        comment: "",
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const docRef = doc(firestore, "Pages", "ReviewsPage");
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setReviewsData(data.reviewsData);
                }
            } catch (error) {
                console.error("Error fetching homepage content: ", error);
            }
        };

        fetchData();
    }, []);

    // Function to add a review to Firebase Firestore
    const addReviewToFirestore = async () => {
        try {
            const reviewRef = doc(firestore, "Pages", "ReviewsPage");
            await updateDoc(reviewRef, {
                reviewsData: [...reviewsData, newReview], // Update the reviewsData array in Firestore
            });
        } catch (error) {
            console.error("Error adding review to Firestore: ", error);
        }
    };

    // Function to handle form input change
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewReview({ ...newReview, [name]: value });
    };

    // Function to handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        addReviewToFirestore();
        setNewReview({ name: "", age: "", comment: "" }); // Clear the form fields after submission
    };

    return (
        <div className="reviewsContainer">
            <Navbar />
            <div className="reviewsContent">
                <div className="reviewsBuffer"></div>
                <h1 className="reviewTitle">Customer Reviews</h1>
                <button
                    className="addTestimonialButton"
                    onClick={() => setAddTestimonial(!addTestimonial)}
                >
                    {addTestimonial ? "Cancel Your Review" : "Add Your Review"}
                </button>
                {addTestimonial && (
                    <form className="addReviewForm" onSubmit={handleSubmit}>
                        <input
                            className="reviewInput"
                            type="text"
                            name="name"
                            value={newReview.name}
                            placeholder="Name"
                            onChange={handleInputChange}
                            required
                        />
                        <input
                            className="reviewInput"
                            type="text"
                            name="age"
                            value={newReview.age}
                            placeholder="Age"
                            onChange={handleInputChange}
                            required
                        />
                        <textarea
                            className="reviewTextarea"
                            name="comment"
                            value={newReview.comment}
                            placeholder="Your review"
                            onChange={handleInputChange}
                            required
                        ></textarea>
                        <button className="submitReviewButton" type="submit">
                            Add Review
                        </button>
                    </form>
                )}
                <p className="reviewTitleP"></p>
                <div className="reviewsList">
                    {reviewsData.map((review, index) => (
                        <div className="review" key={index}>
                            <h3 className="reviewName">Name: {review.name}</h3>
                            <h5 className="reviewAge">Age: {review.age}</h5>
                            <p className="reviewComment">{review.comment}</p>
                        </div>
                    ))}
                </div>
            </div>
            <Footer newClassName="reviewFooter" extension="./" />
        </div>
    );
};

export default Reviews;

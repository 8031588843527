import React, { useState, useEffect } from "react";
import "../styles/CompletedProjects.css";
import Footer from "./Footer";
import Navbar from "./NavBar";
import { initializeApp } from "firebase/app";
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";

const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIRESTORE_API_KEY,
	authDomain: "wishuponaplayhouse.firebaseapp.com",
	projectId: "wishuponaplayhouse",
	storageBucket: "wishuponaplayhouse.appspot.com",
	messagingSenderId: "513377368862",
	appId: "1:513377368862:web:eadda2a0b7bb141ffa670f",
	measurementId: "G-NZ116LS0Q1",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

// Maximum base64 image size (in bytes) to store in Firestore
const MAX_BASE64_SIZE = 1000000; // 1MB

const Project = ({
	project,
	isAdmin,
	showEdits,
	onUpdateProject,
	onDeleteProject,
	isUploading,
	setUploadingProject,
}) => {
	const [currentImageIndex, setCurrentImageIndex] = useState(0);
	const [editedTitle, setEditedTitle] = useState(project.title);
	const [editedDescription, setEditedDescription] = useState(
		project.description || ""
	);

	useEffect(() => {
		setEditedTitle(project.title);
		setEditedDescription(project.description || "");
	}, [project]);

	const nextImage = () => {
		setCurrentImageIndex((currentImageIndex + 1) % project.images.length);
	};

	const prevImage = () => {
		setCurrentImageIndex(
			(currentImageIndex - 1 + project.images.length) % project.images.length
		);
	};

	// Compress an image to reduce its size
	const compressImage = (file) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = (event) => {
				const img = new Image();
				img.src = event.target.result;
				img.onload = () => {
					const canvas = document.createElement("canvas");
					let width = img.width;
					let height = img.height;

					// Calculate new dimensions while maintaining aspect ratio
					const maxDimension = 1200;
					if (width > maxDimension || height > maxDimension) {
						if (width > height) {
							height = Math.round((height * maxDimension) / width);
							width = maxDimension;
						} else {
							width = Math.round((width * maxDimension) / height);
							height = maxDimension;
						}
					}

					canvas.width = width;
					canvas.height = height;
					const ctx = canvas.getContext("2d");
					ctx.drawImage(img, 0, 0, width, height);

					// Get the reduced image in base64
					const quality = 0.7; // Adjust quality as needed (0 to 1)
					const compressedBase64 = canvas.toDataURL(file.type, quality);
					resolve(compressedBase64);
				};
				img.onerror = (error) => reject(error);
			};
			reader.onerror = (error) => reject(error);
		});
	};

	const handleImageUpload = async (event) => {
		const file = event.target.files[0];
		if (!file) return;

		try {
			setUploadingProject(project.id);

			// Compress and convert the file to base64
			const base64Image = await compressImage(file);

			// Check if base64 is too large for Firestore
			if (base64Image.length > MAX_BASE64_SIZE) {
				alert(
					`Image is too large (${Math.round(
						base64Image.length / 1024
					)}KB). Please use a smaller image.`
				);
				setUploadingProject(null);
				return;
			}

			// Create a new array of images for this project
			const newImages = [...project.images];

			// Replace the current image with the new one
			newImages[currentImageIndex] = {
				url: `project_${project.id}_image_${currentImageIndex}_${Date.now()}`,
				base64: base64Image,
			};

			// Update the project with the new image
			onUpdateProject(project.id, {
				...project,
				title: editedTitle,
				description: editedDescription,
				images: newImages,
			});
		} catch (error) {
			console.error("Error uploading image: ", error);
			alert("Failed to upload image. Please try again.");
		} finally {
			setUploadingProject(null);
		}
	};

	const handleAddImage = async () => {
		// Add a new empty image to the project
		const newImages = [
			...project.images,
			{
				url: `project_${project.id}_new_image_${Date.now()}`,
				base64: null,
			},
		];

		onUpdateProject(project.id, {
			...project,
			images: newImages,
		});

		// Switch to the new image
		setCurrentImageIndex(newImages.length - 1);
	};

	const handleRemoveImage = () => {
		if (project.images.length <= 1) {
			alert("Each project must have at least one image.");
			return;
		}

		// Remove the current image
		const newImages = [...project.images];
		newImages.splice(currentImageIndex, 1);

		// Update the project
		onUpdateProject(project.id, {
			...project,
			images: newImages,
		});

		// Adjust the current index if needed
		if (currentImageIndex >= newImages.length) {
			setCurrentImageIndex(newImages.length - 1);
		}
	};

	const handleSaveChanges = () => {
		onUpdateProject(project.id, {
			...project,
			title: editedTitle,
			description: editedDescription,
		});
	};

	// Get image source - either base64 or URL
	const getImageSrc = (image) => {
		return typeof image === "string"
			? image
			: image.base64 || image.url || "./images/image-placeholder.png";
	};

	return (
		<div className="project">
			<div className="image-container">
				<img
					src={getImageSrc(project.images[currentImageIndex])}
					alt={project.title}
					onError={(e) => {
						e.target.onerror = null;
						e.target.src = "./images/image-placeholder.png";
					}}
				/>
				<div className="slideshowBtn">
					<button onClick={prevImage}>&lt;</button>
					<button onClick={nextImage}>&gt;</button>
				</div>
			</div>

			{isAdmin && showEdits ? (
				<div className="project-edit-container">
					<div className="project-image-controls">
						<div className="image-counter">
							Image {currentImageIndex + 1} of {project.images.length}
						</div>
						<div className="image-upload-container">
							<label>Replace Current Image:</label>
							<input
								type="file"
								accept="image/*"
								onChange={handleImageUpload}
								className="file-input"
							/>
							{isUploading && project.id === isUploading && (
								<div className="upload-status">Compressing & Processing...</div>
							)}
							<div className="file-size-note">
								For best results, use images under 1MB
							</div>
						</div>
						<div className="image-action-buttons">
							<button onClick={handleAddImage} className="add-image-btn">
								Add New Image
							</button>
							<button onClick={handleRemoveImage} className="remove-image-btn">
								Remove Current Image
							</button>
						</div>
					</div>

					<div className="project-text-edit">
						<label>Project Title:</label>
						<input
							type="text"
							value={editedTitle}
							onChange={(e) => setEditedTitle(e.target.value)}
							className="title-input"
						/>

						<label>Project Description:</label>
						<textarea
							value={editedDescription}
							onChange={(e) => setEditedDescription(e.target.value)}
							className="description-input"
						/>
					</div>

					<div className="project-actions">
						<button onClick={handleSaveChanges} className="save-project-btn">
							Save Changes
						</button>
						<button
							onClick={() => onDeleteProject(project.id)}
							className="delete-project-btn"
						>
							Delete Project
						</button>
					</div>
				</div>
			) : (
				<>
					<h3>{project.title}</h3>
					<p>{project.description}</p>
				</>
			)}
			<div className="spacer"></div>
		</div>
	);
};

const CompletedProjects = ({ isAdmin, showEdits, setShowEdits }) => {
	const [projects, setProjects] = useState([]);
	const [isUploading, setIsUploading] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const fetchProjects = async () => {
			try {
				setIsLoading(true);
				const docRef = doc(firestore, "Pages", "CompletedProjects");
				const docSnap = await getDoc(docRef);

				if (docSnap.exists() && docSnap.data().projects) {
					setProjects(docSnap.data().projects);
				} else {
					// If no projects exist in Firestore, use the default ones
					const defaultProjects = [
						{
							id: 1,
							title: "Woodland Learning Garden Greenhouse",
							description:
								"A custom cottage design for children to learn about gardening.",
							images: ["./images/cottage.jpeg"],
						},
						{
							id: 2,
							title: "Custom Cottage - Henderson",
							description:
								"A beautiful cottage playhouse for a family in Henderson.",
							images: ["./images/ranch.jpeg"],
						},
						// Add more default projects here
					];
					setProjects(defaultProjects);

					// Save the defaults to Firestore using setDoc instead of updateDoc
					await setDoc(docRef, { projects: defaultProjects });
				}
			} catch (error) {
				console.error("Error fetching projects: ", error);
				// Fallback to default projects if there's an error
				setProjects([
					{
						id: 1,
						title: "Woodland Learning Garden Greenhouse",
						description:
							"A custom cottage design for children to learn about gardening.",
						images: ["./images/cottage.jpeg"],
					},
					{
						id: 2,
						title: "Custom Cottage - Henderson",
						description:
							"A beautiful cottage playhouse for a family in Henderson.",
						images: ["./images/ranch.jpeg"],
					},
				]);
			} finally {
				setIsLoading(false);
			}
		};

		fetchProjects();
	}, []);

	const handleUpdateProject = async (projectId, updatedProject) => {
		try {
			// Find the project index
			const projectIndex = projects.findIndex((p) => p.id === projectId);
			if (projectIndex === -1) return;

			// Create a new array with the updated project
			const updatedProjects = [...projects];
			updatedProjects[projectIndex] = updatedProject;

			// Update local state
			setProjects(updatedProjects);

			// Update Firestore - use setDoc to handle the case where document doesn't exist
			const docRef = doc(firestore, "Pages", "CompletedProjects");
			await setDoc(docRef, {
				projects: updatedProjects,
			});

			console.log("Project updated successfully");
		} catch (error) {
			console.error("Error updating project: ", error);
			alert(
				"Failed to update project. The data might be too large for Firestore."
			);
		}
	};

	const handleDeleteProject = async (projectId) => {
		// Confirm deletion
		if (!window.confirm("Are you sure you want to delete this project?")) {
			return;
		}

		try {
			// Filter out the project to delete
			const updatedProjects = projects.filter((p) => p.id !== projectId);

			// Update local state
			setProjects(updatedProjects);

			// Update Firestore - use setDoc to handle the case where document doesn't exist
			const docRef = doc(firestore, "Pages", "CompletedProjects");
			await setDoc(docRef, {
				projects: updatedProjects,
			});

			console.log("Project deleted successfully");
		} catch (error) {
			console.error("Error deleting project: ", error);
			alert("Failed to delete project");
		}
	};

	const handleAddNewProject = () => {
		// Create a new project with a unique ID
		const newId = Math.max(0, ...projects.map((p) => p.id)) + 1;
		const newProject = {
			id: newId,
			title: "New Project",
			description: "Add a description for your new project.",
			images: [{ url: "./images/image-placeholder.png", base64: null }],
		};

		// Add the new project to the beginning of the list
		const updatedProjects = [newProject, ...projects];
		setProjects(updatedProjects);

		// Update Firestore - use setDoc to handle the case where document doesn't exist
		const docRef = doc(firestore, "Pages", "CompletedProjects");
		setDoc(docRef, {
			projects: updatedProjects,
		}).catch((error) => {
			console.error("Error adding new project: ", error);
			alert("Failed to add new project");
		});
	};

	if (isLoading) {
		return (
			<div className="project-gallery">
				<Navbar />
				<div className="topBuffer"></div>
				<h1>Completed Projects</h1>
				<div className="loading">Loading projects...</div>
				<Footer newClassName={""} extension={"./"} />
			</div>
		);
	}

	return (
		<div className="project-gallery">
			<Navbar />
			<div className="topBuffer"></div>

			{isAdmin && (
				<div className="changeView">
					<button onClick={() => setShowEdits(!showEdits)}>
						Show {showEdits ? "Original" : "Edit"}
					</button>
				</div>
			)}

			<h1>Completed Projects</h1>

			{isAdmin && showEdits && (
				<div className="admin-controls">
					<button onClick={handleAddNewProject} className="add-project-btn">
						Add New Project
					</button>
				</div>
			)}

			<div className="projects">
				{projects.map((project) => (
					<Project
						key={project.id}
						project={project}
						isAdmin={isAdmin}
						showEdits={showEdits}
						onUpdateProject={handleUpdateProject}
						onDeleteProject={handleDeleteProject}
						isUploading={isUploading}
						setUploadingProject={setIsUploading}
					/>
				))}
			</div>
			<Footer newClassName={""} extension={"./"} />
		</div>
	);
};

export default CompletedProjects;

import React, { useState, useEffect } from "react";
import Navbar from "./NavBar";
import "../styles/Home.css";
import Footer from "./Footer";
import { initializeApp } from "firebase/app";
import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore";

const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIRESTORE_API_KEY,
	authDomain: "wishuponaplayhouse.firebaseapp.com",
	projectId: "wishuponaplayhouse",
	storageBucket: "wishuponaplayhouse.appspot.com",
	messagingSenderId: "513377368862",
	appId: "1:513377368862:web:eadda2a0b7bb141ffa670f",
	measurementId: "G-NZ116LS0Q1",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

// Maximum base64 image size (in bytes) to store in Firestore
const MAX_BASE64_SIZE = 1000000; // 1MB

function Home({ isAdmin, showEdits, setShowEdits }) {
	const [slideIndex, setSlideIndex] = useState(0);
	const [title, setTitle] = useState("");
	const [paragraph, setParagraph] = useState("");
	const [slideshowImages, setSlideshowImages] = useState([
		{ url: "./images/ranch.jpeg", alt: "ranch playhouse", base64: null },
		{ url: "./images/cottage.jpeg", alt: "cottage playhouse", base64: null },
		{
			url: "./images/sweet-main.jpeg",
			alt: "sweet-main playhouse",
			base64: null,
		},
		{
			url: "./images/clubhouse.jpeg",
			alt: "clubhouse playhouse",
			base64: null,
		},
	]);
	const [isUploading, setIsUploading] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const docRef = doc(firestore, "Pages", "HomePage");
				const docSnap = await getDoc(docRef);
				if (docSnap.exists()) {
					const data = docSnap.data();
					console.log(data);
					setTitle(data.title);
					setParagraph(data.paragraph);
					// Load slideshow images from Firebase if available
					if (data.slideshowImages && data.slideshowImages.length > 0) {
						setSlideshowImages(data.slideshowImages);
					}
				}
			} catch (error) {
				console.error("Error fetching homepage content: ", error);
			}
		};

		fetchData();

		const interval = setInterval(() => {
			setSlideIndex((prevIndex) => (prevIndex + 1) % slideshowImages.length);
		}, 3500);
		return () => clearInterval(interval);
	}, [slideshowImages.length]);

	const handleTitleChange = (event) => {
		setTitle(event.target.value);
	};

	const handleParagraphChange = (event) => {
		setParagraph(event.target.value);
	};

	const handleImageAltChange = (index, event) => {
		const newImages = [...slideshowImages];
		newImages[index].alt = event.target.value;
		setSlideshowImages(newImages);
	};

	// Convert file to base64
	// eslint-disable-next-line
	const getBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});
	};

	// Compress an image to reduce its size
	const compressImage = (file) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = (event) => {
				const img = new Image();
				img.src = event.target.result;
				img.onload = () => {
					const canvas = document.createElement("canvas");
					let width = img.width;
					let height = img.height;

					// Calculate new dimensions while maintaining aspect ratio
					const maxDimension = 1200;
					if (width > maxDimension || height > maxDimension) {
						if (width > height) {
							height = Math.round((height * maxDimension) / width);
							width = maxDimension;
						} else {
							width = Math.round((width * maxDimension) / height);
							height = maxDimension;
						}
					}

					canvas.width = width;
					canvas.height = height;
					const ctx = canvas.getContext("2d");
					ctx.drawImage(img, 0, 0, width, height);

					// Get the reduced image in base64
					const quality = 0.7; // Adjust quality as needed (0 to 1)
					const compressedBase64 = canvas.toDataURL(file.type, quality);
					resolve(compressedBase64);
				};
				img.onerror = (error) => reject(error);
			};
			reader.onerror = (error) => reject(error);
		});
	};

	const handleImageUpload = async (index, event) => {
		const file = event.target.files[0];
		if (!file) return;

		try {
			setIsUploading(true);

			// Compress and convert the file to base64
			const base64Image = await compressImage(file);

			// Check if base64 is too large for Firestore
			if (base64Image.length > MAX_BASE64_SIZE) {
				alert(
					`Image is too large (${Math.round(
						base64Image.length / 1024
					)}KB). Please use a smaller image.`
				);
				setIsUploading(false);
				return;
			}

			// Update the state with the new image
			const newImages = [...slideshowImages];

			// Create a unique name for reference
			const timestamp = new Date().getTime();
			const fileName = `${timestamp}_${file.name}`;

			newImages[index] = {
				url: fileName, // Use filename as a reference
				alt: newImages[index].alt,
				base64: base64Image, // Store the image as base64
			};

			setSlideshowImages(newImages);
		} catch (error) {
			console.error("Error uploading image: ", error);
			alert("Failed to upload image. Please try again.");
		} finally {
			setIsUploading(false);
		}
	};

	const addImageSlide = () => {
		setSlideshowImages([
			...slideshowImages,
			{
				url: "./images/placeholder.jpg",
				alt: "new playhouse image",
				base64: null,
			},
		]);
	};

	const removeImageSlide = (index) => {
		if (slideshowImages.length > 1) {
			const newImages = [...slideshowImages];
			newImages.splice(index, 1);
			setSlideshowImages(newImages);
		} else {
			alert("You must have at least one image in the slideshow.");
		}
	};

	const handleSaveChanges = async () => {
		try {
			const docRef = doc(firestore, "Pages", "HomePage");
			await updateDoc(docRef, {
				title: title,
				paragraph: paragraph,
				slideshowImages: slideshowImages,
			});
			console.log("Homepage content updated successfully.");
			alert("Homepage content updated successfully.");
		} catch (error) {
			console.error("Error updating homepage content: ", error);
			alert("Error saving changes. Firestore document might be too large.");
		}
	};

	// Get the display URL for an image (either base64 or regular URL)
	const getImageDisplayUrl = (image) => {
		return image.base64 || image.url;
	};

	return (
		<div>
			<Navbar />
			<div className="topBuffer"></div>
			{isAdmin && (
				<div className="changeView">
					<button onClick={() => setShowEdits(!showEdits)}>
						Show {showEdits ? "Original" : "Edit"}
					</button>
				</div>
			)}
			<div className="MainContainer">
				<div className="slideshow-container">
					{slideshowImages.map((image, index) => (
						<div
							key={index}
							className={`mySlides fade ${
								slideIndex === index ? "active" : ""
							}`}
						>
							<img
								src={getImageDisplayUrl(image)}
								style={{ width: "100%" }}
								alt={image.alt}
								onError={(e) => {
									e.target.onerror = null;
									e.target.src = "./images/image-placeholder.png";
								}}
							/>
						</div>
					))}
				</div>

				<div className="mainContainerText">
					{showEdits ? (
						<>
							<p>
								Title:
								<input type="text" value={title} onChange={handleTitleChange} />
							</p>
							<p>
								Paragraph:
								<textarea
									value={paragraph}
									onChange={handleParagraphChange}
									className="editbody"
								></textarea>
							</p>

							<div className="slideshow-editor">
								<h3>Slideshow Images:</h3>
								<p className="image-help-text">
									Upload new images directly from your device. Images are
									automatically compressed and stored with your site data.
								</p>
								{slideshowImages.map((image, index) => (
									<div key={index} className="image-edit-container">
										<div className="image-preview">
											{getImageDisplayUrl(image) ? (
												<img
													src={getImageDisplayUrl(image)}
													alt={image.alt}
													className="thumbnail"
													onError={(e) => {
														e.target.onerror = null;
														e.target.src = "./images/image-placeholder.png";
													}}
												/>
											) : (
												<div className="no-image">No image selected</div>
											)}
										</div>
										<div>
											<label>Upload Image {index + 1}:</label>
											<input
												type="file"
												accept="image/*"
												onChange={(e) => handleImageUpload(index, e)}
												className="file-input"
											/>
											{isUploading && (
												<div className="upload-status">
													Compressing & Processing...
												</div>
											)}
											<div className="file-size-note">
												For best results, use images under 1MB
											</div>
										</div>
										<div>
											<label>Image Alt Text:</label>
											<input
												type="text"
												value={image.alt}
												onChange={(e) => handleImageAltChange(index, e)}
											/>
										</div>
										<button
											onClick={() => removeImageSlide(index)}
											className="remove-image-btn"
										>
											Remove
										</button>
									</div>
								))}
								<button className="add-image-btn" onClick={addImageSlide}>
									Add Image
								</button>
							</div>

							<button className="saveChangesBtn" onClick={handleSaveChanges}>
								Save Changes
							</button>
						</>
					) : (
						<>
							<h1>{title}</h1>
							<p style={{ whiteSpace: "pre-line" }}>{paragraph}</p>
						</>
					)}
				</div>
			</div>
			<Footer newClassName={""} extension={"./"} />
		</div>
	);
}

export default Home;

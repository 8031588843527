import React, { useState, useEffect } from "react";
import IndividualPlayhouse from "./IndividualPlayhouse";
import { initializeApp } from "firebase/app";
import { getFirestore, doc, getDoc } from "firebase/firestore";

const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIRESTORE_API_KEY,
	authDomain: "wishuponaplayhouse.firebaseapp.com",
	projectId: "wishuponaplayhouse",
	storageBucket: "wishuponaplayhouse.appspot.com",
	messagingSenderId: "513377368862",
	appId: "1:513377368862:web:eadda2a0b7bb141ffa670f",
	measurementId: "G-NZ116LS0Q1",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

function Clubhouse({ ...props }) {
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [price, setPrice] = useState("");
	const [features, setFeatures] = useState([]);
	const [interiorOptions, setInteriorOptions] = useState([]);
	const [exteriorOptions, setExteriorOptions] = useState([]);
	const [playhouseMeasurements, setPlayhouseMeasurements] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const docRef = doc(firestore, "Pages", "clubhousePage");
				const docSnap = await getDoc(docRef);
				if (docSnap.exists()) {
					const data = docSnap.data();
					console.log("Clubhouse data from Firestore:", data);
					console.log("Price type:", typeof data.price);
					setTitle(data.title);
					setDescription(data.description);
					setPrice(data.price);
					setFeatures(data.features || []);
					setInteriorOptions(data.interiorOptions || []);
					setExteriorOptions(data.exteriorOptions || []);
					setPlayhouseMeasurements(data.playhouseMeasurements || []);
				}
			} catch (error) {
				console.error("Error fetching homepage content: ", error);
			}
		};

		fetchData();
	}, []);
	// const name = "The Clubhouse";
	// const price = "$5400.00";
	// const description =
	//     "This awesome Clubhouse makes the perfect hideaway for your little gang to hang out.  Weathered wood warning signs keep out any trespassers from entering this secret Clubhouse. A peephole in the front door lets members peek out to see who's there. Old hubcaps and license plates add to its character.  The Clubhouse gives boys a fun and secret place to call their own.";
	// const features = [
	//     ["Custom Dutch Door with Plexiglas Window & Hardware", '23"w x 53"h'],
	//     [
	//         "Four Aluminum Single Hung Working Windows With Grids, Screens & Safety Glass",
	//         'Front - 20"w x 30"h (2) Sides - 36"w x 30"h (2)',
	//     ],
	//     ["Two Sets Of Shutters", '9"w x 30"h (Each)'],
	//     [" Cottage Lap Siding"],
	//     [" Gingerbread Fascia"],
	//     [" Asphalt Roof Shingles"],
	//     ["Unfinished Interior"],
	//     ["Plywood Floor"],
	//     ["Treated Floor Joists"],
	// ];
	// const interiorOptions = [
	//     ["Simulated Hardwood Floor", "$220.00"],
	//     [
	//         "Primed White Beadboard Walls, Baseboards, Window & Door Casing",
	//         "$400.00",
	//     ],
	//     ["Interior Paint", "$300.00"],
	// ];
	// const exteriorOptions = [
	//     ["Chimney", "$100.00"],
	//     ["License Plates On Fascia -6", "$30.00"],
	//     ["Skull and Cross Bones Peephole", "$30.00"],
	//     ["Personalized Plaque Over Door", "$125.00"],
	//     ["Deck - 3' x 10'", "$250.00"],
	//     [
	//         "Rail – Wood, hub caps and painted warning signs or white PVC Severe Weather",
	//         "$175.00",
	//     ],
	//     ["Exterior Paint", "$400.00"],
	// ];
	//     const playhouseMeasurements = `Exterior - 10'w x 8'd
	// Interior - 7'4"w x 9'4"d
	// Exterior Height - 9' h
	// Interior Ceiling Height - 6'9"h`;
	return (
		<div className="aboutContainer">
			<IndividualPlayhouse
				name={title}
				price={price}
				description={description}
				features={features}
				interiorOptions={interiorOptions}
				exteriorOptions={exteriorOptions}
				playhouseMeasurements={playhouseMeasurements}
				pageName={"clubhousePage"}
				isAdmin={props.isAdmin}
				setShowEdits={props.setShowEdits}
				showEdits={props.showEdits}
			/>
		</div>
	);
}

export default Clubhouse;

import React, { useEffect, useState } from "react";
import Navbar from "../NavBar";
import Footer from "../Footer";
import "../../styles/Customize.css";
import { initializeApp } from "firebase/app";
import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIRESTORE_API_KEY,
    authDomain: "wishuponaplayhouse.firebaseapp.com",
    projectId: "wishuponaplayhouse",
    storageBucket: "wishuponaplayhouse.appspot.com",
    messagingSenderId: "513377368862",
    appId: "1:513377368862:web:eadda2a0b7bb141ffa670f",
    measurementId: "G-NZ116LS0Q1",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

function Customize({ ...props }) {
    const [title, setTitle] = useState("");
    const [paragraph, setParagraph] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            try {
                const docRef = doc(firestore, "Pages", "CustomizePage");
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    console.log(data);
                    setTitle(data.title);
                    setParagraph(data.description);
                }
            } catch (error) {
                console.error("Error fetching homepage content: ", error);
            }
        };

        fetchData();
    }, []);

    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    };

    const handleParagraphChange = (event) => {
        setParagraph(event.target.value);
    };

    const handleSaveChanges = async () => {
        try {
            const docRef = doc(firestore, "Pages", "CustomizePage");
            await updateDoc(docRef, {
                title: title,
                description: paragraph,
            });
            console.log("Customize Page content updated successfully.");
            alert("Homepage content updated successfully.");
        } catch (error) {
            console.error("Error updating homepage content: ", error);
        }
    };

    return (
        <div>
            <Navbar />
            <div className="topBuffer"></div>
            {props.isAdmin && (
                <div className="changeView">
                    <button
                        onClick={() => props.setShowEdits(!props.showEdits)}
                    >
                        Show {props.showEdits ? "Original" : "Edit"}
                    </button>
                </div>
            )}
            {!props.showEdits ? (
                <div className="customize">
                    <h1>{title}</h1>
                    <p style={{ whiteSpace: "pre-line" }}>{paragraph}</p>
                </div>
            ) : (
                <>
                    <p>
                        Title:
                        <input
                            type="text"
                            value={title}
                            onChange={handleTitleChange}
                        />
                    </p>
                    <p>
                        Description:
                        <textarea
                            value={paragraph}
                            onChange={handleParagraphChange}
                            className="editbody"
                        ></textarea>
                    </p>
                    <button onClick={handleSaveChanges}>Save Changes</button>
                </>
            )}
            <Footer newClassName={""} extension={"../../"} />
        </div>
    );
}

export default Customize;

import React, { useState, useEffect } from "react";
import Navbar from "../NavBar";
import Footer from "../Footer";
import "../../styles/FAQ.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faChevronDown,
	faChevronUp,
	faTrash,
	faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { initializeApp } from "firebase/app";
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";

const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIRESTORE_API_KEY,
	authDomain: "wishuponaplayhouse.firebaseapp.com",
	projectId: "wishuponaplayhouse",
	storageBucket: "wishuponaplayhouse.appspot.com",
	messagingSenderId: "513377368862",
	appId: "1:513377368862:web:eadda2a0b7bb141ffa670f",
	measurementId: "G-NZ116LS0Q1",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

const FAQ = ({ isAdmin, showEdits, setShowEdits }) => {
	const [isOpen, setIsOpen] = useState([]);
	const [faqTitle, setFaqTitle] = useState("Frequently Asked Questions");
	const [faqItems, setFaqItems] = useState([
		{
			id: 1,
			question: "Are the playhouses waterproof?",
			answer:
				"Yes, your playhouse will not leak. However, the doors are not completely watertight, and a wind-driven rain may bring a trickle of water into the house.",
		},
		{
			id: 2,
			question: "Are these kits?",
			answer:
				"No, our playhouses are handcrafted and built on-site by master craftsmen. There is no assembly required by the owner, freight charges, additional materials to purchase, or costly cranes involved.",
		},
		{
			id: 3,
			question: "How long does it take to build the playhouse?",
			answer:
				"After your deposit is received, and a schedule has been set, a standard playhouse will take approximately three to five days to complete. Custom playhouses lead time will be determined by their scale and design.",
		},
		{
			id: 4,
			question: "What are the recommended ages?",
			answer:
				"Generally we recommend our playhouses for children ranging from 2 to 10 years of age. However, each child's imagination and play habits are different.",
		},
		{
			id: 5,
			question: "Can I change the paint colors?",
			answer:
				"Yes there are usually two to three color schemes to select for the exterior and one for the interior. We suggest selecting from the Benjamin Moore ® color palette the choice is yours.",
		},
		{
			id: 6,
			question: "Can the size of the playhouses be changed?",
			answer:
				"Yes you can increase or decrease the size of your playhouse. The order then becomes custom and a price change will be determined based on the size difference.",
		},
		{
			id: 7,
			question: "Are the windows operable?",
			answer:
				"Yes the windows are all single hung with tempered glass and screens. The Plexiglas window in the front door of The Cottage and The Sweet Shoppe are not operable.",
		},
		{
			id: 8,
			question: "Can you replicate the style of our home?",
			answer:
				"Yes we can design a miniature of your home or any custom style you can dream up.",
		},
	]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		// Initialize isOpen array based on the number of FAQ items
		setIsOpen(Array(faqItems.length).fill(false));

		const fetchFAQData = async () => {
			try {
				setIsLoading(true);
				const docRef = doc(firestore, "Pages", "FAQPage");
				const docSnap = await getDoc(docRef);

				if (docSnap.exists()) {
					const data = docSnap.data();
					if (data.title) setFaqTitle(data.title);
					if (data.items && data.items.length > 0) {
						setFaqItems(data.items);
						setIsOpen(Array(data.items.length).fill(false));
					}
				} else {
					// If document doesn't exist, create it with default values
					await setDoc(docRef, {
						title: faqTitle,
						items: faqItems,
					});
				}
			} catch (error) {
				console.error("Error fetching FAQ data:", error);
			} finally {
				setIsLoading(false);
			}
		};

		fetchFAQData();
		// eslint-disable-next-line
	}, []);

	const toggleAnswer = (index) => {
		const newIsOpen = [...isOpen];
		newIsOpen[index] = !newIsOpen[index];
		setIsOpen(newIsOpen);
	};

	const handleTitleChange = (event) => {
		setFaqTitle(event.target.value);
	};

	const handleQuestionChange = (id, value) => {
		const updatedItems = faqItems.map((item) =>
			item.id === id ? { ...item, question: value } : item
		);
		setFaqItems(updatedItems);
	};

	const handleAnswerChange = (id, value) => {
		const updatedItems = faqItems.map((item) =>
			item.id === id ? { ...item, answer: value } : item
		);
		setFaqItems(updatedItems);
	};

	const handleAddFAQ = () => {
		const newId = Math.max(0, ...faqItems.map((item) => item.id)) + 1;
		const newItem = {
			id: newId,
			question: "New Question",
			answer: "New Answer",
		};

		const updatedItems = [...faqItems, newItem];
		setFaqItems(updatedItems);
		setIsOpen([...isOpen, false]);
	};

	const handleRemoveFAQ = (id) => {
		if (faqItems.length <= 1) {
			alert("You must have at least one FAQ item.");
			return;
		}

		if (window.confirm("Are you sure you want to remove this FAQ?")) {
			const updatedItems = faqItems.filter((item) => item.id !== id);
			setFaqItems(updatedItems);
			setIsOpen(Array(updatedItems.length).fill(false));
		}
	};

	const handleSaveChanges = async () => {
		try {
			const docRef = doc(firestore, "Pages", "FAQPage");
			await setDoc(docRef, {
				title: faqTitle,
				items: faqItems,
			});
			alert("FAQ content updated successfully");
		} catch (error) {
			console.error("Error saving FAQ content:", error);
			alert("Error saving changes. Please try again.");
		}
	};

	if (isLoading) {
		return (
			<div>
				<Navbar />
				<div className="faq-container">
					<div className="topBuffer"></div>
					<div className="loading">Loading FAQ content...</div>
				</div>
				<Footer newClassName="faq-footer" extension={"../../"} />
			</div>
		);
	}

	return (
		<div>
			<Navbar />
			<div className="faq-container">
				<div className="topBuffer"></div>

				{isAdmin && (
					<div className="changeView">
						<button onClick={() => setShowEdits(!showEdits)}>
							Show {showEdits ? "Original" : "Edit"}
						</button>
					</div>
				)}

				<div className="faq-content">
					{showEdits ? (
						// Edit Mode
						<>
							<div className="title-edit">
								<label>Page Title:</label>
								<input
									type="text"
									value={faqTitle}
									onChange={handleTitleChange}
									className="edit-input"
								/>
							</div>

							{faqItems.map((item, index) => (
								<div key={item.id} className="faq-item-edit">
									<div className="question-edit">
										<label>Question:</label>
										<input
											type="text"
											value={item.question}
											onChange={(e) =>
												handleQuestionChange(item.id, e.target.value)
											}
											className="edit-input"
										/>
									</div>
									<div className="answer-edit">
										<label>Answer:</label>
										<textarea
											value={item.answer}
											onChange={(e) =>
												handleAnswerChange(item.id, e.target.value)
											}
											className="edit-textarea"
										/>
									</div>
									<button
										className="remove-faq-button"
										onClick={() => handleRemoveFAQ(item.id)}
									>
										<FontAwesomeIcon icon={faTrash} /> Remove
									</button>
								</div>
							))}

							<div className="button-container">
								<button className="add-faq-button" onClick={handleAddFAQ}>
									<FontAwesomeIcon icon={faPlus} /> Add New FAQ
								</button>
								<button className="save-button" onClick={handleSaveChanges}>
									Save All Changes
								</button>
							</div>
						</>
					) : (
						// Display Mode
						<>
							<h1 className="faq-heading">{faqTitle}</h1>
							<div className="spacer"></div>
							{faqItems.map((item, index) => (
								<div key={item.id} className="faq-item">
									<h2
										className="faq-question"
										onClick={() => toggleAnswer(index)}
									>
										{item.question}{" "}
										{!isOpen[index] ? (
											<FontAwesomeIcon icon={faChevronDown} />
										) : (
											<FontAwesomeIcon icon={faChevronUp} />
										)}
									</h2>
									{isOpen[index] && <p className="faq-answer">{item.answer}</p>}
								</div>
							))}
						</>
					)}
				</div>
			</div>
			<Footer newClassName="faq-footer" extension={"../../"} />
		</div>
	);
};

export default FAQ;

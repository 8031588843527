import "./App.css";
import Home from "./components/Home";
import About from "./components/pages/About";
import Playhouse from "./components/pages/Playhouse";
import Reviews from "./components/pages/Reviews";
import Ranch from "./components/pages/Ranch";
import Cottage from "./components/pages/Cottage";
import Clubhouse from "./components/pages/Clubhouse";
import SweetShoppe from "./components/pages/SweetShoppe";
import Customize from "./components/pages/Customize";
import Login from "./components/pages/Login";
import FAQ from "./components/pages/FAQ";
import Ordering from "./components/pages/Ordering";
import CompletedProjects from "./components/CompletedProjects";
import Contact from "./components/Contact";
import {
	BrowserRouter as Router,
	Route,
	Routes,
	useLocation,
} from "react-router-dom";
import { useEffect, useState } from "react";

function ScrollToTop() {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return null;
}

function App() {
	const [isAdmin, setIsAdmin] = useState(false);
	const [showEdits, setShowEdits] = useState(false);
	useEffect(() => {
		if (isAdmin) {
			setShowEdits(true);
		}
	}, [isAdmin]);
	return (
		<Router>
			<ScrollToTop />
			<Routes>
				<Route
					path="/"
					element={
						<Home
							isAdmin={isAdmin}
							showEdits={showEdits}
							setShowEdits={setShowEdits}
						/>
					}
				/>
				<Route
					path="/about"
					element={
						<About
							isAdmin={isAdmin}
							showEdits={showEdits}
							setShowEdits={setShowEdits}
						/>
					}
				/>
				<Route
					path="/playhouses"
					element={
						<Playhouse
							isAdmin={isAdmin}
							showEdits={showEdits}
							setShowEdits={setShowEdits}
						/>
					}
				/>
				<Route
					path="/reviews"
					element={
						<Reviews
							isAdmin={isAdmin}
							showEdits={showEdits}
							setShowEdits={setShowEdits}
						/>
					}
				/>
				<Route
					path="/playhouses/ranch"
					element={
						<Ranch
							isAdmin={isAdmin}
							showEdits={showEdits}
							setShowEdits={setShowEdits}
						/>
					}
				/>
				<Route
					path="/playhouses/cottage"
					element={
						<Cottage
							isAdmin={isAdmin}
							showEdits={showEdits}
							setShowEdits={setShowEdits}
						/>
					}
				/>
				<Route
					path="/playhouses/clubhouse"
					element={
						<Clubhouse
							isAdmin={isAdmin}
							showEdits={showEdits}
							setShowEdits={setShowEdits}
						/>
					}
				/>
				<Route
					path="/playhouses/sweet-shoppe"
					element={
						<SweetShoppe
							isAdmin={isAdmin}
							showEdits={showEdits}
							setShowEdits={setShowEdits}
						/>
					}
				/>
				<Route
					path="/playhouses/customize"
					element={
						<Customize
							isAdmin={isAdmin}
							showEdits={showEdits}
							setShowEdits={setShowEdits}
						/>
					}
				/>
				<Route
					path="/playhouses/faq"
					element={
						<FAQ
							isAdmin={isAdmin}
							showEdits={showEdits}
							setShowEdits={setShowEdits}
						/>
					}
				/>
				<Route
					path="/playhouses/ordering"
					element={
						<Ordering
							isAdmin={isAdmin}
							showEdits={showEdits}
							setShowEdits={setShowEdits}
						/>
					}
				/>
				<Route
					path="/completed-projects"
					element={
						<CompletedProjects
							isAdmin={isAdmin}
							showEdits={showEdits}
							setShowEdits={setShowEdits}
						/>
					}
				/>
				<Route
					path="/contact"
					element={
						<Contact
							isAdmin={isAdmin}
							showEdits={showEdits}
							setShowEdits={setShowEdits}
						/>
					}
				/>
				<Route path="/login" element={<Login setIsAdmin={setIsAdmin} />} />
			</Routes>
		</Router>
	);
}

export default App;

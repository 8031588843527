import React, { useState, useEffect } from "react";
import Navbar from "../NavBar";
import Footer from "../Footer";
import "../../styles/About.css";
import { initializeApp } from "firebase/app";
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";

const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIRESTORE_API_KEY,
	authDomain: "wishuponaplayhouse.firebaseapp.com",
	projectId: "wishuponaplayhouse",
	storageBucket: "wishuponaplayhouse.appspot.com",
	messagingSenderId: "513377368862",
	appId: "1:513377368862:web:eadda2a0b7bb141ffa670f",
	measurementId: "G-NZ116LS0Q1",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

// Maximum base64 image size (in bytes) to store in Firestore
const MAX_BASE64_SIZE = 1000000; // 1MB

function About({ ...props }) {
	const [title, setTitle] = useState("");
	const [paragraph, setParagraph] = useState("");
	const [title2, setTitle2] = useState("");
	const [paragraph2, setParagraph2] = useState("");
	const [aboutImage, setAboutImage] = useState({
		url: "",
		base64: null,
	});
	const [isUploading, setIsUploading] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const docRef = doc(firestore, "Pages", "AboutPage");
				const docSnap = await getDoc(docRef);
				if (docSnap.exists()) {
					const data = docSnap.data();
					console.log(data);
					setTitle(data.title);
					setParagraph(data.paragraph);
					setTitle2(data.title2);
					setParagraph2(data.paragraph2);

					// Load image if available
					if (data.aboutImage) {
						setAboutImage(data.aboutImage);
					}
				}
			} catch (error) {
				console.error("Error fetching About page content: ", error);
			}
		};

		fetchData();
	}, []);

	const handleTitleChange = (event) => {
		setTitle(event.target.value);
	};

	const handleParagraphChange = (event) => {
		setParagraph(event.target.value);
	};

	const handleTitle2Change = (event) => {
		setTitle2(event.target.value);
	};

	const handleParagraph2Change = (event) => {
		setParagraph2(event.target.value);
	};

	// Compress an image to reduce its size
	const compressImage = (file) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = (event) => {
				const img = new Image();
				img.src = event.target.result;
				img.onload = () => {
					const canvas = document.createElement("canvas");
					let width = img.width;
					let height = img.height;

					// Calculate new dimensions while maintaining aspect ratio
					const maxDimension = 1200;
					if (width > maxDimension || height > maxDimension) {
						if (width > height) {
							height = Math.round((height * maxDimension) / width);
							width = maxDimension;
						} else {
							width = Math.round((width * maxDimension) / height);
							height = maxDimension;
						}
					}

					canvas.width = width;
					canvas.height = height;
					const ctx = canvas.getContext("2d");
					ctx.drawImage(img, 0, 0, width, height);

					// Get the reduced image in base64
					const quality = 0.7; // Adjust quality as needed (0 to 1)
					const compressedBase64 = canvas.toDataURL(file.type, quality);
					resolve(compressedBase64);
				};
				img.onerror = (error) => reject(error);
			};
			reader.onerror = (error) => reject(error);
		});
	};

	const handleImageUpload = async (event) => {
		const file = event.target.files[0];
		if (!file) return;

		try {
			setIsUploading(true);

			// Compress and convert the file to base64
			const base64Image = await compressImage(file);

			// Check if base64 is too large for Firestore
			if (base64Image.length > MAX_BASE64_SIZE) {
				alert(
					`Image is too large (${Math.round(
						base64Image.length / 1024
					)}KB). Please use a smaller image.`
				);
				setIsUploading(false);
				return;
			}

			// Create a new image object
			const newImage = {
				url: `about_image_${Date.now()}`,
				base64: base64Image,
			};

			// Update state
			setAboutImage(newImage);
		} catch (error) {
			console.error("Error uploading image: ", error);
			alert("Failed to upload image. Please try again.");
		} finally {
			setIsUploading(false);
		}
	};

	// Get image source - either base64 or URL
	const getImageSrc = (image) => {
		return image.base64 || image.url;
	};

	const handleSaveChanges = async () => {
		try {
			const docRef = doc(firestore, "Pages", "AboutPage");
			await setDoc(docRef, {
				title: title,
				paragraph: paragraph,
				title2: title2,
				paragraph2: paragraph2,
				aboutImage: aboutImage,
			});
			console.log("About page content updated successfully.");
			alert("About Page content updated successfully.");
		} catch (error) {
			console.error("Error updating about page content: ", error);
			alert("Error updating content. The image might be too large.");
		}
	};

	return (
		<div className="aboutContainer">
			<Navbar />
			<div className="topBuffer"></div>
			{props.isAdmin && (
				<div className="changeView">
					<button onClick={() => props.setShowEdits(!props.showEdits)}>
						Show {props.showEdits ? "Original" : "Edit"}
					</button>
				</div>
			)}
			{!props.showEdits ? (
				<div>
					<div className="aboutFirstContainer">
						<img
							src={getImageSrc(aboutImage)}
							alt="about us"
							className="aboutImage"
							onError={(e) => {
								e.target.onerror = null;
								e.target.src = "./images/about.jpg";
							}}
						/>
						<div className="aboutText">
							<h2>{title}</h2>
							<p>{paragraph}</p>
						</div>
					</div>
					<div className="spacer"></div>
					<div className="bottomAbout">
						<h2>{title2}</h2>
						<p>{paragraph2}</p>
					</div>
				</div>
			) : (
				<div>
					<div className="aboutFirstContainer">
						<div className="image-section">
							<img
								src={getImageSrc(aboutImage)}
								alt="about us"
								className="aboutImage"
								onError={(e) => {
									e.target.onerror = null;
									e.target.src = "./images/about.jpg";
								}}
							/>
							<div className="image-upload-container">
								<label>Change About Image:</label>
								<input
									type="file"
									accept="image/*"
									onChange={handleImageUpload}
									className="file-input"
								/>
								{isUploading && (
									<div className="upload-status">
										Compressing & Processing...
									</div>
								)}
								<div className="file-size-note">
									For best results, use images under 1MB
								</div>
							</div>
						</div>
						<div className="aboutText">
							<p>
								Title:
								<input type="text" value={title} onChange={handleTitleChange} />
							</p>
							<p>
								Paragraph:
								<textarea
									value={paragraph}
									onChange={handleParagraphChange}
									className="editbody"
								></textarea>
							</p>
						</div>
					</div>
					<div className="spacer"></div>
					<div className="bottomAbout">
						<p>
							Title:
							<input type="text" value={title2} onChange={handleTitle2Change} />
						</p>
						<p>
							Paragraph:
							<textarea
								value={paragraph2}
								onChange={handleParagraph2Change}
								className="editbody"
							></textarea>
						</p>
					</div>
					<button className="saveChangesBtn" onClick={handleSaveChanges}>
						Save Changes
					</button>
				</div>
			)}
			<Footer newClassName={""} extension={"./"} />
		</div>
	);
}

export default About;

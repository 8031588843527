import React, { useState } from "react";
import "../../styles/Login.css";
import { Link } from "react-router-dom";
import Navbar from "../NavBar";

const Login = ({ ...props }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [continueHome, setContinueHome] = useState(false);
    const handleSubmit = (e) => {
        e.preventDefault();
        if (
            email === process.env.REACT_APP_ADMIN_USERNAME &&
            password === process.env.REACT_APP_ADMIN_PASSWORD
        ) {
            props.setIsAdmin(true);
            setContinueHome(true);
        }
    };

    return (
        <div className="SignInContainer">
            {!continueHome ? (
                <div>
                    <Navbar />
                    <h2>Sign In</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="inputField">
                            <label>Username:</label>
                            <input
                                type="text"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        <div className="inputField">
                            <label>Password:</label>
                            <input
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </div>
                        <button>
                            <Link to="/">Return Home</Link>
                        </button>
                        <button type="submit">Sign In</button>
                    </form>
                </div>
            ) : (
                <div>
                    <Navbar />
                    <h2>Success Logging In!</h2>
                    <button>
                        <Link to="/">Continue to Home</Link>
                    </button>
                </div>
            )}
        </div>
    );
};

export default Login;

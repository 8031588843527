import React, { useState, useEffect } from "react";
import "../styles/NavBar.css"; // Import the CSS file for styling
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

const Navbar = () => {
    const [isSmallScreen, setIsSmallScreen] = useState(
        window.innerWidth < 1200
    );
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const showDropdown = (status) => {
        setIsDropdownOpen(status);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 1200);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handlePopoverToggle = () => {
        setIsPopoverOpen(!isPopoverOpen);
        console.log(isDropdownOpen);
    };

    const renderMenu = () => {
        if (isSmallScreen) {
            return (
                <div className="popover-menu-container">
                    <h1 className="pageTitle">
                        <Link to="/" className="pageTitleLink">
                            Wish Upon A Playhouse
                        </Link>
                    </h1>
                    <FontAwesomeIcon
                        icon={faBars}
                        onClick={handlePopoverToggle}
                        className="menuBars"
                    />
                    {isPopoverOpen && (
                        <div className="popover-menu">
                            <Link to="/">Home</Link>
                            <Link to="/playhouses">Playhouses</Link>
                            <Link to="/reviews">Reviews</Link>
                            <Link to="/about">About</Link>
                            <Link to="/playhouses/faq">FAQ</Link>
                            <Link to="/completed-projects">
                                Completed Projects
                            </Link>
                            <Link to="/playhouses/customize">Customize</Link>
                            <Link to="/playhouses/ordering">Ordering</Link>
                            <Link to="/contact">Contact</Link>
                        </div>
                    )}
                </div>
            );
        } else {
            return (
                <div className="navbarContainer">
                    <h1 className="pageTitle">
                        <Link to="/" className="pageTitleLink">
                            Wish Upon A Playhouse
                        </Link>
                    </h1>
                    <nav className="navbar">
                        <ul>
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li
                                className="dropdown"
                                onMouseEnter={() => showDropdown(true)}
                                onMouseLeave={() => showDropdown(false)}
                            >
                                <Link to="/playhouses">Playhouses</Link>
                                <div className="dropdown-menu">
                                    <Link
                                        to="/playhouses/ranch"
                                        className="dropdown-link"
                                    >
                                        Ranch
                                    </Link>
                                    <Link
                                        to="/playhouses/sweet-shoppe"
                                        className="dropdown-link"
                                    >
                                        Sweet Shop
                                    </Link>
                                    <Link
                                        to="/playhouses/cottage"
                                        className="dropdown-link"
                                    >
                                        Cottage
                                    </Link>
                                    <Link
                                        to="/playhouses/clubhouse"
                                        className="dropdown-link"
                                    >
                                        Clubhouse
                                    </Link>
                                    <Link
                                        to="/playhouses/customize"
                                        className="dropdown-link"
                                    >
                                        Customize
                                    </Link>
                                    <Link
                                        to="/playhouses/ordering"
                                        className="dropdown-link"
                                    >
                                        Ordering
                                    </Link>
                                    <Link
                                        to="/playhouses/faq"
                                        className="dropdown-link"
                                    >
                                        FAQ
                                    </Link>
                                </div>
                            </li>
                            <li>
                                <Link to="/completed-projects">
                                    Completed Projects
                                </Link>
                            </li>
                            <li>
                                <Link to="/reviews">Reviews</Link>
                            </li>
                            <li>
                                <Link to="/about">About</Link>
                            </li>
                            <li>
                                <Link to="/contact">Contact</Link>
                            </li>
                        </ul>
                    </nav>
                </div>
            );
        }
    };

    return <nav className="navbar">{renderMenu()}</nav>;
};

export default Navbar;

import React, { useEffect, useState } from "react";
import Navbar from "../NavBar";
import Footer from "../Footer";
import "../../styles/Playhouse.css";
import { Link } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore";

const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIRESTORE_API_KEY,
	authDomain: "wishuponaplayhouse.firebaseapp.com",
	projectId: "wishuponaplayhouse",
	storageBucket: "wishuponaplayhouse.appspot.com",
	messagingSenderId: "513377368862",
	appId: "1:513377368862:web:eadda2a0b7bb141ffa670f",
	measurementId: "G-NZ116LS0Q1",
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

// Maximum base64 image size (in bytes) to store in Firestore
const MAX_BASE64_SIZE = 1000000; // 1MB

function Playhouse({ ...props }) {
	const [cottageTitle, setCottageTitle] = useState("");
	const [cottageParagraph, setCottageParagraph] = useState("");
	const [cottageImage, setCottageImage] = useState({
		url: "",
		base64: null,
	});

	const [ranchTitle, setRanchTitle] = useState("");
	const [ranchParagraph, setRanchParagraph] = useState("");
	const [ranchImage, setRanchImage] = useState({
		url: "",
		base64: null,
	});

	const [sweetshopTitle, setSweetshopTitle] = useState("");
	const [sweetshopParagraph, setSweetshopParagraph] = useState("");
	const [sweetshopImage, setSweetshopImage] = useState({
		url: "",
		base64: null,
	});

	const [clubhouseTitle, setClubhouseTitle] = useState("");
	const [clubhouseParagraph, setClubhouseParagraph] = useState("");
	const [clubhouseImage, setClubhouseImage] = useState({
		url: "",
		base64: null,
	});

	const [isUploading, setIsUploading] = useState(false);
	const [uploadingPlayhouse, setUploadingPlayhouse] = useState(null);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const docRef = doc(firestore, "Pages", "PlayhousesPage");
				const docSnap = await getDoc(docRef);
				if (docSnap.exists()) {
					const data = docSnap.data();
					console.log(data);
					setCottageTitle(data.cottageTitle);
					setCottageParagraph(data.cottageParagraph);
					setRanchTitle(data.ranchTitle);
					setRanchParagraph(data.ranchParagraph);
					setSweetshopTitle(data.sweetshopTitle);
					setSweetshopParagraph(data.sweetshopParagraph);
					setClubhouseTitle(data.clubhouseTitle);
					setClubhouseParagraph(data.clubhouseParagraph);

					// Load image data if available
					if (data.cottageImage) setCottageImage(data.cottageImage);
					if (data.ranchImage) setRanchImage(data.ranchImage);
					if (data.sweetshopImage) setSweetshopImage(data.sweetshopImage);
					if (data.clubhouseImage) setClubhouseImage(data.clubhouseImage);
				}
			} catch (error) {
				console.error("Error fetching playhouse content: ", error);
			}
		};

		fetchData();
	}, []);

	// Compress an image to reduce its size
	const compressImage = (file) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = (event) => {
				const img = new Image();
				img.src = event.target.result;
				img.onload = () => {
					const canvas = document.createElement("canvas");
					let width = img.width;
					let height = img.height;

					// Calculate new dimensions while maintaining aspect ratio
					const maxDimension = 1200;
					if (width > maxDimension || height > maxDimension) {
						if (width > height) {
							height = Math.round((height * maxDimension) / width);
							width = maxDimension;
						} else {
							width = Math.round((width * maxDimension) / height);
							height = maxDimension;
						}
					}

					canvas.width = width;
					canvas.height = height;
					const ctx = canvas.getContext("2d");
					ctx.drawImage(img, 0, 0, width, height);

					// Get the reduced image in base64
					const quality = 0.7; // Adjust quality as needed (0 to 1)
					const compressedBase64 = canvas.toDataURL(file.type, quality);
					resolve(compressedBase64);
				};
				img.onerror = (error) => reject(error);
			};
			reader.onerror = (error) => reject(error);
		});
	};

	const handleImageUpload = async (playhouseName, event) => {
		const file = event.target.files[0];
		if (!file) return;

		try {
			setIsUploading(true);
			setUploadingPlayhouse(playhouseName);

			// Compress the image to base64
			const base64Image = await compressImage(file);

			// Check if base64 is too large for Firestore
			if (base64Image.length > MAX_BASE64_SIZE) {
				alert(
					`Image is too large (${Math.round(
						base64Image.length / 1024
					)}KB). Please use a smaller image.`
				);
				setIsUploading(false);
				setUploadingPlayhouse(null);
				return;
			}

			// Create a unique name for reference
			const timestamp = new Date().getTime();
			const fileName = `${timestamp}_${file.name}`;

			const newImageData = {
				url: fileName, // Store filename as reference
				base64: base64Image, // Store the compressed image data
			};

			// Update the appropriate state based on playhouse type
			switch (playhouseName) {
				case "cottage":
					setCottageImage(newImageData);
					break;
				case "ranch":
					setRanchImage(newImageData);
					break;
				case "sweetshop":
					setSweetshopImage(newImageData);
					break;
				case "clubhouse":
					setClubhouseImage(newImageData);
					break;
				default:
					break;
			}
		} catch (error) {
			console.error("Error uploading image: ", error);
			alert("Failed to upload image. Please try again.");
		} finally {
			setIsUploading(false);
			setUploadingPlayhouse(null);
		}
	};

	const handleUpdate = async (playhouseId, title, description) => {
		try {
			const docRef = doc(firestore, "Pages", "PlayhousesPage");

			// Determine which image data to update based on playhouse ID
			let imageUpdateData = {};

			switch (playhouseId) {
				case "cottage":
					imageUpdateData = { cottageImage: cottageImage };
					break;
				case "ranch":
					imageUpdateData = { ranchImage: ranchImage };
					break;
				case "sweetshop":
					imageUpdateData = { sweetshopImage: sweetshopImage };
					break;
				case "clubhouse":
					imageUpdateData = { clubhouseImage: clubhouseImage };
					break;
				default:
					break;
			}

			await updateDoc(docRef, {
				[playhouseId + "Title"]: title,
				[playhouseId + "Paragraph"]: description,
				...imageUpdateData,
			});
			console.log("Playhouse updated successfully!");
			alert("Playhouse updated successfully!");
		} catch (error) {
			console.error("Error updating playhouse: ", error);
			alert("Error updating playhouse. The image might be too large.");
		}
	};

	// Get the display URL for an image (either base64 or regular URL)
	const getImageDisplayUrl = (image) => {
		return image?.base64 || image?.url || "";
	};

	const playhouses = [
		{
			id: 1,
			name: "cottage",
			imageObj: cottageImage,
			title: cottageTitle,
			description: cottageParagraph,
			links: "/playhouses/cottage",
		},
		{
			id: 2,
			name: "ranch",
			imageObj: ranchImage,
			title: ranchTitle,
			description: ranchParagraph,
			links: "/playhouses/ranch",
		},
		{
			id: 3,
			name: "sweetshop",
			imageObj: sweetshopImage,
			title: sweetshopTitle,
			description: sweetshopParagraph,
			links: "/playhouses/sweet-shoppe",
		},
		{
			id: 4,
			name: "clubhouse",
			imageObj: clubhouseImage,
			title: clubhouseTitle,
			description: clubhouseParagraph,
			links: "/playhouses/clubhouse",
		},
	];

	return (
		<div className="aboutContainer">
			<Navbar />
			<div className="topBuffer"></div>
			{props.isAdmin && (
				<div className="changeView">
					<button onClick={() => props.setShowEdits(!props.showEdits)}>
						Show {props.showEdits ? "Original" : "Edit"}
					</button>
				</div>
			)}
			<div className="playhousesContainer">
				{playhouses.map((playhouse) => (
					<div className="playhouseCard" key={playhouse.id}>
						<img
							src={getImageDisplayUrl(playhouse.imageObj)}
							alt={`Playhouse ${playhouse.id}`}
							onError={(e) => {
								e.target.onerror = null;
								e.target.src = "/images/image-placeholder.png";
							}}
						/>
						{props.showEdits ? (
							<>
								<div className="playhouse-image-upload">
									<label>Change Image:</label>
									<input
										type="file"
										accept="image/*"
										onChange={(e) => handleImageUpload(playhouse.name, e)}
										className="file-input"
									/>
									{isUploading && uploadingPlayhouse === playhouse.name && (
										<div className="upload-status">
											Compressing & Processing...
										</div>
									)}
									<div className="file-size-note">
										For best results, use images under 1MB
									</div>
								</div>
								<p>
									<input
										type="text"
										value={playhouse.title}
										onChange={(e) =>
											playhouse.id === 1
												? setCottageTitle(e.target.value)
												: playhouse.id === 2
												? setRanchTitle(e.target.value)
												: playhouse.id === 3
												? setSweetshopTitle(e.target.value)
												: setClubhouseTitle(e.target.value)
										}
									/>
								</p>
								<p>
									<textarea
										className="EditableParagraph"
										value={playhouse.description}
										onChange={(e) =>
											playhouse.id === 1
												? setCottageParagraph(e.target.value)
												: playhouse.id === 2
												? setRanchParagraph(e.target.value)
												: playhouse.id === 3
												? setSweetshopParagraph(e.target.value)
												: setClubhouseParagraph(e.target.value)
										}
									/>
								</p>
								<button
									className="saveChangesBtn2"
									onClick={() =>
										handleUpdate(
											playhouse.name,
											playhouse.title,
											playhouse.description
										)
									}
								>
									Update
								</button>
							</>
						) : (
							<>
								<h2>{playhouse.title}</h2>
								<p>{playhouse.description}</p>
								<Link to={playhouse.links}>Read More!</Link>
							</>
						)}
					</div>
				))}
			</div>
			<Footer newClassName={""} extension={"./"} />
		</div>
	);
}

export default Playhouse;

import React, { useState, useEffect } from "react";
import Navbar from "../NavBar";
import Footer from "../Footer";
import "../../styles/IndividualPlayhouse.css";
import { initializeApp } from "firebase/app";
import { getFirestore, doc, updateDoc, deleteField } from "firebase/firestore";

const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIRESTORE_API_KEY,
	authDomain: "wishuponaplayhouse.firebaseapp.com",
	projectId: "wishuponaplayhouse",
	storageBucket: "wishuponaplayhouse.appspot.com",
	messagingSenderId: "513377368862",
	appId: "1:513377368862:web:eadda2a0b7bb141ffa670f",
	measurementId: "G-NZ116LS0Q1",
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

function IndividualPlayhouse({ ...props }) {
	const [editedTitle, setEditedTitle] = useState(props.name);
	const [editedPrice, setEditedPrice] = useState(props.price);
	const [rawPriceInput, setRawPriceInput] = useState(props.price);
	const [editedDescription, setEditedDescription] = useState(props.description);
	const [editedFeatures, setEditedFeatures] = useState(props.features);
	const [editedInteriorOptions, setEditedInteriorOptions] = useState(
		props.interiorOptions
	);
	const [editedExteriorOptions, setEditedExteriorOptions] = useState(
		props.exteriorOptions
	);
	const [editedMeasurements, setEditedMeasurements] = useState(
		props.playhouseMeasurements
	);
	// eslint-disable-next-line
	const [isUpdatingPrice, setIsUpdatingPrice] = useState(false);
	const [isSavingChanges, setIsSavingChanges] = useState(false);

	useEffect(() => {
		setEditedDescription(props.description);
		setEditedFeatures(props.features);
		setEditedInteriorOptions(props.interiorOptions);
		setEditedExteriorOptions(props.exteriorOptions);
		setEditedMeasurements(props.playhouseMeasurements);
		setEditedTitle(props.name);
		setEditedPrice(props.price);
	}, [props]);

	const handleDescriptionChange = (e) => {
		setEditedDescription(e.target.value);
	};

	const handleFeatureChange = (index, field, value) => {
		const newFeatures = [...editedFeatures];
		newFeatures[index][field] = value;
		setEditedFeatures(newFeatures);
	};

	const handleInteriorOptionChange = (index, field, value) => {
		const newInteriorOptions = [...editedInteriorOptions];
		newInteriorOptions[index][field] = value;
		setEditedInteriorOptions(newInteriorOptions);
	};

	const handleExteriorOptionChange = (index, field, value) => {
		const newExteriorOptions = [...editedExteriorOptions];
		newExteriorOptions[index][field] = value;
		setEditedExteriorOptions(newExteriorOptions);
	};

	const handleMeasurementChange = (index, value) => {
		const newMeasurements = [...editedMeasurements];
		newMeasurements[index] = value;
		setEditedMeasurements(newMeasurements);
	};

	const handleTitleChange = (e) => {
		setEditedTitle(e.target.value);
	};

	const handlePriceChange = (e) => {
		const newValue = e.target.value;
		setRawPriceInput(newValue);
		console.log("Raw price input changed to:", newValue);
	};

	const updatePriceDirectly = async () => {
		try {
			setIsUpdatingPrice(true);

			// Get the raw input without any processing
			let priceToSave = rawPriceInput;

			// Add $ if needed
			if (!priceToSave.startsWith("$") && priceToSave.trim() !== "") {
				priceToSave = "$" + priceToSave;
			}

			console.log("Attempting to save price:", priceToSave);

			// Use the Firebase Admin SDK directly
			const docRef = doc(firestore, "Pages", props.pageName);

			// Use a completely separate transaction for price only
			try {
				// First completely delete the field
				await updateDoc(docRef, {
					price: deleteField(),
				});

				console.log("Price field deleted, now setting new value");

				// Wait for deletion to complete
				await new Promise((resolve) => setTimeout(resolve, 800));

				// Set it to a temporary value
				await updateDoc(docRef, {
					price: "UPDATING",
				});

				console.log("Temporary value set, now setting final value");

				// Wait for temp value to be set
				await new Promise((resolve) => setTimeout(resolve, 500));

				// Now set the new price value
				await updateDoc(docRef, {
					price: priceToSave,
				});

				console.log("PRICE UPDATED SUCCESSFULLY");
				setEditedPrice(priceToSave);
				alert("Price updated to " + priceToSave);
			} catch (innerError) {
				console.error("Firebase operation failed:", innerError);
				alert("Error updating price: " + innerError.message);
			}
		} catch (error) {
			console.error("Error in price update process:", error);
			alert("Failed to update price: " + error.message);
		} finally {
			setIsUpdatingPrice(false);
		}
	};

	const handleSaveChanges = async () => {
		try {
			setIsSavingChanges(true);
			console.log("Saving changes with values:", {
				title: editedTitle,
				price: editedPrice,
				description: editedDescription,
				features: editedFeatures,
				interiorOptions: editedInteriorOptions,
				exteriorOptions: editedExteriorOptions,
				playhouseMeasurements: editedMeasurements,
			});

			const docRef = doc(firestore, "Pages", props.pageName);
			await updateDoc(docRef, {
				title: editedTitle,
				price: editedPrice,
				description: editedDescription,
				features: editedFeatures,
				interiorOptions: editedInteriorOptions,
				exteriorOptions: editedExteriorOptions,
				playhouseMeasurements: editedMeasurements,
			});
			console.log("Firebase update completed successfully");
			alert("Changes saved successfully!");
		} catch (error) {
			console.error("Error updating document: ", error);
			alert("Failed to save changes. Please try again later.");
		} finally {
			setIsSavingChanges(false);
		}
	};

	return (
		<div className="aboutContainer">
			<div className="topBuffer"></div>
			<Navbar />
			{props.isAdmin && (
				<div className="changeView">
					<button onClick={() => props.setShowEdits(!props.showEdits)}>
						Show {props.showEdits ? "Original" : "Edit"}
					</button>
				</div>
			)}
			<div className="topTitle">
				{props.showEdits ? (
					<input value={editedTitle} onChange={handleTitleChange} />
				) : (
					<h1>{props.name}</h1>
				)}
				<h3>
					Base Price:{" "}
					{props.showEdits ? (
						<>
							<input
								value={rawPriceInput}
								onChange={handlePriceChange}
								placeholder="$0000.00"
								style={{
									width: "200px",
									border: "2px solid #007BFF",
									padding: "8px",
									fontSize: "16px",
									marginRight: "10px",
								}}
							/>
							<button
								onClick={updatePriceDirectly}
								style={{
									backgroundColor: "#9C27B0",
									color: "white",
									padding: "8px 16px",
									fontSize: "16px",
									cursor: "pointer",
									borderRadius: "4px",
									border: "none",
								}}
							>
								SAVE PRICE
							</button>
							<div
								style={{ marginTop: "10px", fontSize: "14px", color: "#666" }}
							>
								Type your price above and click SAVE PRICE to update
							</div>
						</>
					) : (
						props.price
					)}
				</h3>
			</div>
			{/* Description */}
			{props.showEdits ? (
				<div>
					<h1 className="descriptionContainer">Description</h1>
					<textarea
						className="descriptionContainerChanging"
						value={editedDescription}
						onChange={handleDescriptionChange}
					/>
				</div>
			) : (
				<p className="descriptionContainer">{props.description}</p>
			)}

			{/* Features */}
			<div className="tablesContainer">
				{props.showEdits ? (
					<div>
						<h1>Features</h1>
						<table>
							<tbody>
								{editedFeatures.map((feature, index) => (
									<tr key={index}>
										<td>
											<input
												value={feature.description}
												onChange={(e) =>
													handleFeatureChange(
														index,
														"description",
														e.target.value
													)
												}
											/>
										</td>
										<td className="dimensions">
											<input
												value={feature.dimensions}
												onChange={(e) =>
													handleFeatureChange(
														index,
														"dimensions",
														e.target.value
													)
												}
											/>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				) : (
					<div>
						<h2>Features</h2>
						<table>
							<tbody>
								{props.features.map((feature, index) => (
									<tr key={index}>
										<td>{feature.description}</td>
										<td className="dimensions">{feature.dimensions}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				)}

				{/* Interior Options */}
				<div>
					{props.showEdits ? (
						<div>
							<h1>Interior Options</h1>
							<table>
								<tbody>
									{editedInteriorOptions.map((option, index) => (
										<tr key={index}>
											<td>
												<input
													value={option.description}
													onChange={(e) =>
														handleInteriorOptionChange(
															index,
															"description",
															e.target.value
														)
													}
												/>
											</td>
											<td className="dimensions">
												<input
													value={option.price}
													onChange={(e) =>
														handleInteriorOptionChange(
															index,
															"price",
															e.target.value
														)
													}
												/>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					) : (
						<div>
							<h2>Interior Options</h2>
							<table>
								<tbody>
									{props.interiorOptions.map((option, index) => (
										<tr key={index}>
											<td>{option.description}</td>
											<td className="dimensions">{option.price}</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					)}
					{/* Exterior Options */}
					{props.showEdits ? (
						<div>
							<h1>Exterior Options</h1>
							<table>
								<tbody>
									{editedExteriorOptions.map((option, index) => (
										<tr key={index}>
											<td>
												<input
													value={option.description}
													onChange={(e) =>
														handleExteriorOptionChange(
															index,
															"description",
															e.target.value
														)
													}
												/>
											</td>
											<td className="dimensions">
												<input
													value={option.price}
													onChange={(e) =>
														handleExteriorOptionChange(
															index,
															"price",
															e.target.value
														)
													}
												/>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					) : (
						<div>
							<h2>Exterior Options</h2>
							<table>
								<tbody>
									{props.exteriorOptions.map((option, index) => (
										<tr key={index}>
											<td>{option.description}</td>
											<td className="dimensions">{option.price}</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					)}
				</div>
			</div>

			{/* Other JSX code */}
			{props.showEdits ? (
				<div className="measurements">
					<h2>Playhouse Measurements</h2>
					{editedMeasurements.map((measurement, index) => (
						<div key={index}>
							<input
								value={measurement}
								onChange={(e) => handleMeasurementChange(index, e.target.value)}
							/>
						</div>
					))}
				</div>
			) : (
				<div className="measurements">
					<h2>Playhouse Measurements</h2>
					<div style={{ whiteSpace: "pre-line" }}>
						{props.playhouseMeasurements.map((spec, index) => (
							<p key={index}>{spec}</p>
						))}
					</div>
				</div>
			)}
			{/* Save changes button */}
			{props.showEdits && (
				<button
					className="saveChangesBtn"
					onClick={handleSaveChanges}
					disabled={isSavingChanges}
					style={{
						backgroundColor: isSavingChanges ? "#cccccc" : "#4CAF50",
						color: "white",
						padding: "12px 20px",
						fontSize: "16px",
						margin: "20px 0",
						cursor: isSavingChanges ? "default" : "pointer",
						borderRadius: "4px",
						border: "none",
					}}
				>
					{isSavingChanges ? "Saving Changes..." : "Save Changes"}
				</button>
			)}
			<Footer newClassName={""} extension={"../../"} />
		</div>
	);
}

export default IndividualPlayhouse;

import React, { useState, useEffect } from "react";
import Navbar from "../NavBar";
import Footer from "../Footer";
import "../../styles/Ordering.css";
import { initializeApp } from "firebase/app";
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash, faSave } from "@fortawesome/free-solid-svg-icons";

const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIRESTORE_API_KEY,
	authDomain: "wishuponaplayhouse.firebaseapp.com",
	projectId: "wishuponaplayhouse",
	storageBucket: "wishuponaplayhouse.appspot.com",
	messagingSenderId: "513377368862",
	appId: "1:513377368862:web:eadda2a0b7bb141ffa670f",
	measurementId: "G-NZ116LS0Q1",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

function Ordering({ isAdmin, showEdits, setShowEdits }) {
	const [mainTitle, setMainTitle] = useState("Ordering Information");
	const [mainParagraph, setMainParagraph] = useState(
		"To order one of our playhouses or to inquire about a custom playhouse just send us an email or give us a call and we will be able to assist you with your order and answer any questions you may have."
	);
	const [sections, setSections] = useState([
		{
			id: 1,
			title: "Payments:",
			content:
				"A 50% deposit of the cost of the playhouse plus all selected options is required to initiate your order. We accept Visa, Master Card, and American Express. Checks are also accepted.\n\nWhen your deposit is received, finalization of your options, painting and color selections will be made. A scheduled building and installation date will be determined at the time of your order. Our playhouses require approximately 5-7 days to be completed depending on chosen options and weather.",
		},
		{
			id: 2,
			title: "Sales Tax:",
			content: "Denver residence pay 7.65% sales tax.",
		},
		{
			id: 3,
			title: "Color Combinations:",
			content:
				"Selecting paint colors for your playhouse requires 3 to 4 choices. Exterior includes the body, trim, shutters, and interior includes walls and trim. Please refer to Benjamin Moore ® color palette for color names and numbers. If you need help in customizing your colors, we have designers available to create color schemes for you at an additional charge.",
		},
		{
			id: 4,
			title: "Site Preparations:",
			content:
				"Prior to building your playhouse a site must be determined and prepared. The area must be cleared of all debris, roots, grass, rocks etc. The ground must be level due to the weight of each playhouse. We are able to prepare your site for you for an additional charge which will be determined after site inspection.",
		},
		{
			id: 5,
			title: "Final Payment:",
			content:
				"The balance of the invoice cost of the playhouse is due at completion. Payment may be made by Visa, Master Card, American Express or certified check.",
		},
		{
			id: 6,
			title: "Liability:",
			content:
				"Our playhouses are constructed by skilled craftsmen using quality materials designed with durability and safety in mind. We strive to create a safe and fun environment for children to play. Wish Upon a Playhouse assumes no liability after the playhouse has been built on your property. You are responsible for selecting a structure that is appropriate for the developmental level of the children who will use them. Routine inspections, preventive maintenance and play supervision are all the responsibility of the buyer to ensure a play environment that is free of hazard or injury.",
		},
		{
			id: 7,
			title: "Warranty:",
			content:
				"We warrant our playhouses for one year from the date of purchase to repair or replace any part defective in materials or workmanship. This warranty excludes any labor incurred for replacement. We disclaim liability for any buyer alterations, repairs or assembly normal wear and tear, or damages resulting from misuse or abuse.",
		},
	]);

	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			try {
				setIsLoading(true);
				const docRef = doc(firestore, "Pages", "OrderingPage");
				const docSnap = await getDoc(docRef);

				if (docSnap.exists()) {
					const data = docSnap.data();

					if (data.mainTitle) setMainTitle(data.mainTitle);
					if (data.mainParagraph) setMainParagraph(data.mainParagraph);
					if (data.sections && data.sections.length > 0)
						setSections(data.sections);
				} else {
					// If the document doesn't exist yet, create it with the default values
					await setDoc(docRef, {
						mainTitle: mainTitle,
						mainParagraph: mainParagraph,
						sections: sections,
					});
				}
			} catch (error) {
				console.error("Error fetching ordering content: ", error);
			} finally {
				setIsLoading(false);
			}
		};

		fetchData();
		// eslint-disable-next-line
	}, []);

	const handleMainTitleChange = (e) => {
		setMainTitle(e.target.value);
	};

	const handleMainParagraphChange = (e) => {
		setMainParagraph(e.target.value);
	};

	const handleSectionTitleChange = (id, value) => {
		const newSections = sections.map((section) => {
			if (section.id === id) {
				return { ...section, title: value };
			}
			return section;
		});
		setSections(newSections);
	};

	const handleSectionContentChange = (id, value) => {
		const newSections = sections.map((section) => {
			if (section.id === id) {
				return { ...section, content: value };
			}
			return section;
		});
		setSections(newSections);
	};

	const handleAddSection = () => {
		const newId = Math.max(0, ...sections.map((s) => s.id)) + 1;
		const newSection = {
			id: newId,
			title: "New Section",
			content: "Add content for this section.",
		};
		setSections([...sections, newSection]);
	};

	const handleRemoveSection = (id) => {
		if (sections.length <= 1) {
			alert("You must have at least one section.");
			return;
		}

		if (window.confirm("Are you sure you want to remove this section?")) {
			setSections(sections.filter((section) => section.id !== id));
		}
	};

	const handleSaveChanges = async () => {
		try {
			const docRef = doc(firestore, "Pages", "OrderingPage");
			await setDoc(docRef, {
				mainTitle: mainTitle,
				mainParagraph: mainParagraph,
				sections: sections,
			});
			console.log("Ordering content updated successfully.");
			alert("Ordering content updated successfully.");
		} catch (error) {
			console.error("Error updating ordering content: ", error);
			alert("Failed to update content. Please try again.");
		}
	};

	if (isLoading) {
		return (
			<div className="orderingContainer">
				<Navbar />
				<div className="topBuffer"></div>
				<div className="loading">Loading content...</div>
				<Footer newClassName={""} extension={"../../"} />
			</div>
		);
	}

	return (
		<div className="orderingContainer">
			<Navbar />
			<div className="topBuffer"></div>

			{isAdmin && (
				<div className="changeView">
					<button onClick={() => setShowEdits(!showEdits)}>
						{showEdits ? "View Published Version" : "Edit Content"}
					</button>
				</div>
			)}

			{!showEdits ? (
				// Display Mode
				<div className="content-wrapper">
					<h1>{mainTitle}</h1>
					<p className="orderingP">{mainParagraph}</p>

					{sections.map((section) => (
						<React.Fragment key={section.id}>
							<div className="spacer"></div>
							<h3>{section.title}</h3>
							<p className="orderingP" style={{ whiteSpace: "pre-line" }}>
								{section.content}
							</p>
						</React.Fragment>
					))}
				</div>
			) : (
				// Edit Mode
				<div className="edit-wrapper">
					<div className="edit-section">
						<label>Main Title:</label>
						<input
							type="text"
							value={mainTitle}
							onChange={handleMainTitleChange}
							className="title-input"
						/>
					</div>

					<div className="edit-section">
						<label>Main Paragraph:</label>
						<textarea
							value={mainParagraph}
							onChange={handleMainParagraphChange}
							className="content-textarea"
						></textarea>
					</div>

					{sections.map((section) => (
						<div key={section.id} className="section-edit-container">
							<div className="section-header">
								<label>Section Title:</label>
								<input
									type="text"
									value={section.title}
									onChange={(e) =>
										handleSectionTitleChange(section.id, e.target.value)
									}
									className="title-input"
								/>
								<button
									onClick={() => handleRemoveSection(section.id)}
									className="remove-section-btn"
								>
									<FontAwesomeIcon icon={faTrash} /> Remove
								</button>
							</div>

							<div className="section-content">
								<label>Section Content:</label>
								<textarea
									value={section.content}
									onChange={(e) =>
										handleSectionContentChange(section.id, e.target.value)
									}
									className="content-textarea"
								></textarea>
							</div>
						</div>
					))}

					<div className="add-section-container">
						<button onClick={handleAddSection} className="add-section-btn">
							<FontAwesomeIcon icon={faPlus} /> Add New Section
						</button>
					</div>

					<button className="saveChangesBtn" onClick={handleSaveChanges}>
						<FontAwesomeIcon icon={faSave} /> Save All Changes
					</button>
				</div>
			)}

			<div className="spacer"></div>
			<Footer newClassName={""} extension={"../../"} />
		</div>
	);
}

export default Ordering;
